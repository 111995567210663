<template>

	<div>
		<div class='reference_all_contentbottom_title' data-id='"+data[i].haveData+"' :style="{color:(!item.haveData ?'gray' : '#1da6b8' ) ,
			cursor:( !item.haveData ? 'null' : 'pointer'
			) ,fontSize: '18px' }" @click="openJounalDetails">{{item.title}}
		</div>
		<div style='margin-top: 10px'>
			{{item.authorNames==null?'-':item.authorNames}}
		</div>
		<div>
			{{item.sourceTitle==null ? '-' : item.sourceTitle}}"
		</div>
		<div>{{item.year==null ? '-' : item.year}}</div>
		<hr>
	</div>

</template>

<script>
	export default {
		props: ['item'],
		methods: {
			openJounalDetails() {
				//有数据
				if (this.item.haveData) {
					// this.$router.push({
					// 	path: "/journalDetails",
					// 	query: {
					// 		id: this.
					// 	}
					// });
					window.open("journalDetails?id=" + this.item.haveData, "_blank")
				}
			}
		}
	}
</script>

<style>
</style>